require("bootstrap");
import './adopt';

let showMore = false;
function showMoreAboutProject(e) {
  e.preventDefault();
  const slideDown = element => element.style.height = `${element.scrollHeight}px`;
  showMore = !showMore;
  if (showMore) {
    slideDown(document.querySelector('.content.about-project div'));
    this.querySelector('a u').innerHTML = "Pokaż mniej o projekcie";
    this.querySelector('svg').style = "transform: rotate(180deg);";
  } else {
    this.querySelector('a u').innerHTML = "Pokaż więcej o projekcie";
    this.querySelector('svg').style = "transform: rotate(0deg);";
    document.querySelector('.content.about-project div').style = "height: 0;";
  }
}

let contact = false;

function showMoreContact(e) {
  e.preventDefault();
  const slideDown = element => element.style.height = `${element.scrollHeight}px`;
  contact = !contact;
  if (contact) {
    slideDown(document.querySelector('.collapse-contact'));
    this.querySelector('a u').innerHTML = "Pokaż mniej";
    this.querySelector('svg').style = "transform: rotate(180deg);";
  } else {
    this.querySelector('a u').innerHTML = "Pokaż więcej";
    this.querySelector('svg').style = "transform: rotate(0deg);";
    document.querySelector('.collapse-contact').style = "height: 0;";
  }
}

document.querySelector('.show-more.about-project').addEventListener('click', showMoreAboutProject, false);
document.querySelector('.show-more.contact').addEventListener('click', showMoreContact, false);


(() => {
  let navItems = document.querySelectorAll('#menu-menu-1 .nav-link');
  navItems.forEach((item) => {
    item.addEventListener('click', (obj) => {
      obj.preventDefault();
      let href = obj.currentTarget.getAttribute("href");
      if (href.substr(0, 1).includes("#")) {
        document.querySelector(href).scrollIntoView({
          behavior: 'smooth'
        });
      }
    });
  })
})();

$(document).ready(function () {
  $('.text-node').adopt();
  window.onscroll = function () {
    navbarFunction();
  };
  var navbar = document.getElementById('masthead');
  function navbarFunction() {
    if (window.pageYOffset > 0) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  }
    //Qookie
    var sName = "cookiesok";
    $("#close-cookie-warn").click(function () {
        var oExpire = new Date();
        oExpire.setTime((new Date()).getTime() + 3600000 * 24 * 365);
        document.cookie = sName + "=1;expires=" + oExpire;
        $("#cookie-warn").hide("slow");
    });
    var sStr = '; ' + document.cookie + ';';
    var nIndex = sStr.indexOf('; ' + escape(sName) + '=');
    if (nIndex === -1) {
        $("#cookie-warn").show();
    }
});
